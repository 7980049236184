.pg-bg{
    margin: 10px auto 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}
.alert-container {
    position: fixed;
    top: 20px; /* 距离顶部的距离 */
    left: 50%; /* 位于页面水平中间 */
    transform: translateX(-50%);
    z-index: 1000; /* 确保 Alert 组件位于其他内容的上方 */
}

.alert-wrapper {
    width: 300px; /* Alert 组件的宽度 */
}

.alert-message {
    text-align: center; /* 文字居中 */
}
.PasswordBox{
    background: white;
    width: 45rem;
    height: 5rem;
    margin-top:10px;
    margin-bottom: 10px;
    font-size: 2.0rem;
    text-align: center;
    display: flex;
}
.popover{
    width: 43.5rem;
    height: 5rem;
    display: block;
    margin-top:10px;
    margin-bottom: 10px;
    font-size: 1rem;
}
.password{
    margin: auto;
}
.generator-box{
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}
.select{
    display: flex;
    flex-direction: row;
}
